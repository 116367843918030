import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAdd,
  faAnglesLeft,
  faAnglesRight,
  faBars,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCogs,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilter,
  faFireBurner,
  faFireAlt,
  faFireFlameCurved,
  faFlask,
  faGauge,
  faGraduationCap,
  faLock,
  faLockOpen,
  faMinus,
  faSearch,
  faTag,
  faTimes,
  faTrash,
  faThumbsUp,
  faThumbsDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAdd,
  faAnglesLeft,
  faAnglesRight,
  faBars,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCogs,
  faFilter,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFlask,
  faFireBurner,
  faFireAlt,
  faFireFlameCurved,
  faGauge,
  faGraduationCap,
  faLock,
  faLockOpen,
  faMinus,
  faSearch,
  faTag,
  faTimes,
  faTrash,
  faThumbsUp,
  faThumbsDown,
  faUser
);
