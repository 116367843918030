export const icons = [
  "add",
  "angles-left",
  "angles-right",
  "bullseye",
  "calendar",
  "calendar-alt",
  "check",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "chevron-down",
  "cogs",
  "eye",
  "eye-slash",
  "edit",
  "envelope",
  "filter",
  "fire-burner",
  "fire-alt",
  "fire-flame-curved",
  "flask",
  "gauge",
  "graduation-cap",
  "lock",
  "lock-open",
  "minus",
  "search",
  "tag",
  "times",
  "trash",
  "thumbs-up",
  "thumbs-down",
  "bars",
  "user",
] as const;
